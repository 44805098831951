// Http
import $api from '../common/http';

// Models
import { Account, SessionType } from '../models/types';
import { AxiosResponse } from 'axios';

export default class AuthService {

  static async login(loginData: {
    email: string;
    password: string;
    rememberMe: boolean;
  }): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/unified-login', loginData);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async createSession(data: SessionType): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/session', data);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async thirdPartyLogin(code: string, service: string): Promise<AxiosResponse> {
    try {
      return await $api.get(`/auth/login/${service}/teacher?code=${code}`);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async checkAuth(): Promise<{ data: Account }> {
    try {
      return await $api.get('/auth/me');
    } catch (e: unknown) {
      return e as AxiosResponse;
    }
  }

  static async logout() {
    try {
      return await $api.post('/auth/logout');
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }

  static async resetPassword(email: string): Promise<AxiosResponse> {
    try {
      return await $api.post('/notification/reset-password', { email });
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }

  static async varifyPage(key: string, token: string): Promise<{ data: boolean }> {
    try {
      return await $api.get(`/notification/health-check/reset?key=${key}&token=${token}`);
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }

  static async changePassword(data: { password: string; key: string; token: string }): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/change-password', data);
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }

  static async activateAccount(data: { password: string; key: string; token: string }): Promise<AxiosResponse> {
    try {
      return await $api.post('/auth/activate-account', data);
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }

  static async getPersonsList(): Promise<AxiosResponse<AxiosResponse>> {
    try {
      return await $api.get('/auth/get-persons-list');
    } catch (e: unknown) {
      throw e as AxiosResponse;
    }
  }
}
