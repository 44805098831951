// Http
import $api from '../common/http';

// Core
import { AxiosResponse } from 'axios';

// Enums
import { ActivityGradeEnum } from '../common/enums';

export default class CircleService {
  static async getActivity(grade: ActivityGradeEnum): Promise<AxiosResponse> {
    try {
      return await $api.get(`/circle/activities/${grade}`);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async createCircle(data: {
    comment: number
    activity_id: number
  }): Promise<AxiosResponse> {
    try {
      return await $api.post(`/circle`, {
        comment: `${data.comment}%`,
        activity_id: data.activity_id
      });
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }
}
