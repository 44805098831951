// Core
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Services
import InsightsService from '../services/insights.service';
import { EventType } from '../common/enums';

export default class InsightsStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  async getInsights(
    class_id?: number | undefined,
  ): Promise<AxiosResponse> {
    try {
      return await InsightsService.getInsights(class_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getStudentInsights(
    student_id: number,
    type: EventType, days: number, type_id: number | undefined
  ): Promise<AxiosResponse> {
    try {
      return await InsightsService.getStudentInsights(student_id, type, days, type_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getClassesAndEvents(class_id?: number | undefined, quantity?: number | undefined): Promise<AxiosResponse> {
    try {
      return await InsightsService.getClassesAndEvents(class_id, quantity);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getEventDetails(event_id: number): Promise<AxiosResponse> {
    try {
      return await InsightsService.getEventDetails(event_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getConnectionDetails(connection_id: number): Promise<AxiosResponse> {
    try {
      return await InsightsService.getConnectionDetails(connection_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getDetailedInsights(type: EventType, days: number, class_id: number | undefined): Promise<AxiosResponse> {
    try {
      return await InsightsService.getDetailedInsights(type, days, class_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getInsightGraphDataByType(type: EventType, days: number, class_id: number | undefined, type_id: number | undefined): Promise<AxiosResponse> {
    try {
      return await InsightsService.getInsightGraphDataByType(type, days, class_id, type_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

}
