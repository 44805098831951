// Core
import { AxiosResponse } from 'axios';

// Http
import $api from '../common/http';


export default class SupportService {
  static async requestSupport(data: { message: string, type: number }): Promise<AxiosResponse> {
    try {
      return await $api.post("/notification/support", data)
    }  catch (e: any) {
      throw e;
    }
  }
}
