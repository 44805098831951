export const authErrors = {
  INVALID_EMAIL: {
    value: 'INVALID_EMAIL',
    message: 'No account exists for this email.'
  },
  INVALID_PASSWORD: {
    value: 'INVALID_PASSWORD',
    message: 'The password you entered is incorrect.'
  },
  DISABLED: {
    value: 'DISABLED',
    message: 'Account is temporarily disabled'
  },
  UNVERIFIED: {
    value: 'UNVERIFIED',
    message: 'Please verify your email address'
  },
  SAME_EMAIL: {
    value: 'SAME_EMAIL',
    message: 'Email can\'t be the same as the current one'
  }
}
