// Core
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import LoadingComponent from '../../components/loading-component/loading-component';

// Context
import { Context } from '../../index';

// Classes
import './third-login.scss';

// Images
import disabled_icon from '../../assets/img/disabled_icon.svg';
import { authErrors } from '../../common/constants';
import unverified_icon from '../../assets/img/unverified_icon.svg';
import success_icon from '../../assets/img/success_icon.svg';

const ThirdLogin: FC = () => {
  const navigate = useNavigate();

  const {
    store: {
      authStore: { thirdPartyLogin, setAuth, checkAuth, setAccount }
    }
  } = useContext(Context);

  const { service } = useParams();

  const [majorError, setMajorError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authenticate = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

        const response = await thirdPartyLogin(code!, service!);
        if (response.status === 200) {
          const user = await checkAuth();
          if (user) {
            setAccount(user);
            setAuth(true);
            navigate('/', { replace: true });
          }
        } else {
          setMajorError(response.data.message);
        }
      } catch (error) {
        setAuth(false);
        setMajorError('Sorry, there was an error. Try refreshing the page.');
      } finally {
        setIsLoading(false);
      }
    };

    authenticate().then();
  }, []);

  if (isLoading) return (<LoadingComponent />);

  return (
    <div className="third-party">
      {
        majorError === authErrors.UNVERIFIED.value ?
          (
            <div className="login-form error_form">
              <a className="logo-container" href="#" title="Error">
                <img className="logo" src={unverified_icon} alt="Error" />
              </a>
              <div className="error-title">Account Not Verified</div>
              <span className="error-title__subtitle">Please follow the instructions we sent to your email.</span>
              <button
                className="back-to-sign-in"
                style={{ background: '#FFB105' }}
                onClick={() => {
                  window.location.href = '/';
                  setMajorError('');
                }}
              >
                Back to Sign in
              </button>
            </div>
          )
          : majorError === authErrors.DISABLED.value ?
            (
              <div className="login-form error_form">
                <a className="logo-container" href="#" title="Error">
                  <img className="logo" src={disabled_icon} alt="Error" />
                </a>
                <div className="error-title">Account Locked</div>
                <span className="error-title__subtitle">Your account has been temporarily locked due to multiple failed login attempts. Please try again later.</span>
                <button
                  className="back-to-sign-in"
                  style={{
                    backgroundColor: '#FF6331'
                  }}
                  onClick={() => {
                    window.location.href = '/';
                    setMajorError('');
                  }}
                >
                  Back to Sign in
                </button>
              </div>
            ) : majorError.length ?
              (
                <div className="login-form error_form">
                  <a className="logo-container" href="#" title="Error">
                    <img className="logo" src={disabled_icon} alt="Error" />
                  </a>
                  <div className="error-title">Something went wrong</div>
                  <span className="error-title__subtitle">Please try again later.</span>
                  <button
                    className="back-to-sign-in"
                    style={{
                      backgroundColor: '#FF6331'
                    }}
                    onClick={() => {
                      window.location.href = '/';
                      // setMajorError('');
                    }}
                  >
                    Back to Sign in
                  </button>
                </div>
              )
            :
            (
              <div className="login-form error_form">
                <a className="logo-container" href="#" title="Success">
                  <img className="logo" src={success_icon} alt="Success" />
                </a>
                <div className="error-title">Success</div>
                <span className="error-title__subtitle">Welcome to Highfive.
                  <br/>
                  Logging you in...</span>
              </div>
            )
      }
    </div>
  )
};

export default ThirdLogin;
