// Enums
import { ConnectionTypeEnum } from '../enums';

// Images
import understand_0 from '../../assets/img/understand_0.svg';
import understand_0_disabled from '../../assets/img/understand_0_disabled.svg';
import understand_1 from '../../assets/img/understand_1.svg';
import understand_1_disabled from '../../assets/img/understand_1_disabled.svg';
import understand_2 from '../../assets/img/understand_2.svg';
import understand_2_disabled from '../../assets/img/understand_2_disabled.svg';
import engage_0 from '../../assets/img/engage_0.svg';
import engage_0_disabled from '../../assets/img/engage_0_disabled.svg';
import engage_1 from '../../assets/img/engage_1.svg';
import engage_1_disabled from '../../assets/img/engage_1_disabled.svg';
import engage_2 from '../../assets/img/engage_2.svg';
import engage_2_disabled from '../../assets/img/engage_2_disabled.svg';
import empower_0 from '../../assets/img/empower_0.svg';
import empower_0_disabled from '../../assets/img/empower_0_disabled.svg';
import empower_1 from '../../assets/img/empower_1.svg';
import empower_1_disabled from '../../assets/img/empower_1_disabled.svg';
import empower_2 from '../../assets/img/empower_2.svg';
import empower_2_disabled from '../../assets/img/empower_2_disabled.svg';

export const getOptionLogo = (connection_type: ConnectionTypeEnum, index: number, disabled: boolean): string => {
  const images = {
    [ConnectionTypeEnum.Understand]: [understand_0, understand_1, understand_2],
    [ConnectionTypeEnum.Engage]: [engage_0, engage_1, engage_2],
    [ConnectionTypeEnum.Empower]: [empower_0, empower_1, empower_2],
  };

  const disabledImages = {
    [ConnectionTypeEnum.Understand]: [understand_0_disabled, understand_1_disabled, understand_2_disabled],
    [ConnectionTypeEnum.Engage]: [engage_0_disabled, engage_1_disabled, engage_2_disabled],
    [ConnectionTypeEnum.Empower]: [empower_0_disabled, empower_1_disabled, empower_2_disabled],
  };

  return disabled ? disabledImages[connection_type][index] : images[connection_type][index];
};
