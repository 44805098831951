import { CalendarData, GroupedDayStatistic } from '../../models/types';

export const calculateProgressForDayCheckin = (groupedData: GroupedDayStatistic): CalendarData[] => {
  const dateList = getDates(7);
return dateList.map((date) => {
  return {
    date: date,
    progress: groupedData[date]?.filter((i: any) => i.checkin_id).length || 0,
    total: groupedData[date]?.length || 0
  };
}).sort((a, b) =>
  new Date(a.date).getTime() - new Date(b.date).getTime());
}

const getDates = (days: number): string[] => {
  const dates = [];
  for (let i = 0; i < days; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split('T')[0]);
  }
  return dates;
};
