// Http
import $api from '../common/http';

// Models
import { ExtendedStudentsListType, StudentsQuery } from '../models/types';
import { buildQueryString } from '../common/helpers';
import { AxiosResponse } from 'axios';

export default class StudentsService {

  static async getStudents(query: StudentsQuery): Promise<{
    data: ExtendedStudentsListType
  }> {
      const queryString = buildQueryString(query);
      return await $api.get(`/students?${queryString}`);
  }

  static async getStudent(id: number): Promise<AxiosResponse> {
      return await $api.get(`/students/${id}`);
  }

}
