import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RolesEnum } from '../../common/enums';
import { Account } from '../../models/types';

interface IProps {
  children: React.ReactElement;
  roles?: RolesEnum[];
  account: Account;
  isAuth: boolean;
}

const ProtectedRoute: React.FC<IProps> = ({ children, roles = [], account, isAuth }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/login', { replace: true });
    } else if (roles.length > 0 && !roles.includes(account.role)) {
      navigate('/', { replace: true });
    }
  }, [isAuth, account.role, roles, navigate]);

  return isAuth && roles.includes(account.role) ? children : null;
};

export default ProtectedRoute;
