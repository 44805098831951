// Other
import App from './App';
import Store from './stores/store';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';

// Core
import React from 'react';
import { config as dotenvConfig } from 'dotenv';
import ReactDOM from 'react-dom/client';
import './index.css';
dotenvConfig({ path: '../.env' });

const store = new Store();

export const Context = React.createContext<{
  store: Store
}>({
  store
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Context.Provider value={{ store }}>
    <DevSupport ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
    >
      <App />
    </DevSupport>
  </Context.Provider>
);
