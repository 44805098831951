import { StudentsQuery } from '../../models/types';

export const buildQueryString = (query: StudentsQuery | Record<string, any>) => {
  const params = new URLSearchParams();

  if (query.limit !== undefined) params.append('limit', query.limit);
  if (query.offset !== undefined) params.append('offset', query.offset);
  if (query.sort_by) params.append('sort_by', query.sort_by);
  if (query.sort_direction) params.append('sort_direction', query.sort_direction);
  if (query.class_id !== undefined) params.append('class_id', query.class_id);

  return params.toString();
};
