// Core
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Types
import { ShortConnectionType } from '../models/types';

// Services
import ConnectionService from '../services/connection.service';

export default class ConnectionStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  activeConnection: number | undefined = undefined;

  setActiveConnection = (value: number | undefined) => (this.activeConnection = value);

  active_connections: ShortConnectionType[] = [];

  setActiveConnections = (connections: ShortConnectionType[]) => (this.active_connections = connections);

  async getConnectionData(connection_id: number): Promise<AxiosResponse> {
    try {
      return await ConnectionService.getConnectionData(connection_id);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async completeConnection(connection_id: number, resolutions: string[], selected_option: number): Promise<AxiosResponse> {
    try {
      return await ConnectionService.completeConnection(connection_id, resolutions, selected_option);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }
}
