// Core
import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';

// Store
import Store from './store';

// Services
import CheckInService from '../services/check-in.service';

// Types
import { Behavior, CalendarData, EventToCreateType, GroupedDayStatistic } from '../models/types';

export default class CheckInStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  isCheckIn = false;

  daysData: GroupedDayStatistic | undefined = undefined;
  daysValues: CalendarData[] = [];
  behaviors: Behavior[] = [];
  streak: number = 0;

  setIsCheckIn = (bool: boolean) => (this.isCheckIn = bool);

  setDaysData = (value: GroupedDayStatistic) => (this.daysData = value);

  setDaysValues = (value: CalendarData[]) => (this.daysValues = value);

  setBehaviors = (value: Behavior[]) => (this.behaviors = value);

  setStreak = (value: number) => (this.streak = value);

  async isFirstCheckIn(): Promise<{ data: boolean }> {
    try {
      return await CheckInService.isFirstCheckIn()
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getEventsStatistics(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getEventsStatistics()
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getDaysStatistics(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getDaysStatistics()
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getStudentsByClassId(class_id: number): Promise<AxiosResponse> {
    try {
      return await CheckInService.getStudentsByClassId(class_id)
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async createCheckIn(class_id: number, date: Date): Promise<AxiosResponse> {
    try {
      return await CheckInService.createCheckIn(class_id, date)
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async updateCheckIn(id: number, data: EventToCreateType[]): Promise<AxiosResponse> {
    try {
      return await CheckInService.updateCheckIn(id, data)
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async createEvents(data: EventToCreateType[]): Promise<AxiosResponse> {
    try {
      return await CheckInService.createEvents(data)
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getAllBehaviors(): Promise<AxiosResponse> {
    try {
      return await CheckInService.getAllBehaviors()
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  async getCheckIn(id: number): Promise<AxiosResponse> {
    try {
      return await CheckInService.getCheckIn(id)
    }catch (error: unknown) {
      return error as AxiosResponse;
    }
  }
}
