// Core
import React, { FC } from 'react';

// Classes
import './loading-component.scss';

// Images
import loading_icon from '../../assets/img/loading_icon.svg';

const LoadingComponent: FC = () => {
  return (
    <div className="loading-container">
      <img src={loading_icon} alt="Loading..." />
    </div>
  );
}

export default LoadingComponent;
