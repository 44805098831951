export const fullTeacherRoleName = (role: string, is_admin: boolean, is_super_admin: boolean) => {
  if (is_super_admin) {
    return 'Super-administrator';
  }

  if (is_admin) {
    return 'Administrator';
  }
  return 'Teacher';
};
