import React from 'react';

const MobileCleverAuth: React.FC = () => {
  return (
    <div>

    </div>
  );
}

export default MobileCleverAuth;
