// Http
import $api from '../common/http';

// Core
import { AxiosResponse } from 'axios';


export default class ConnectionService {
  static async getConnectionData(connection_id: number): Promise<AxiosResponse> {
    try {
      return await $api.get(`/connection/${connection_id}`);
    }catch (error: any) {
      return error;
    }
  }

  static async completeConnection(connection_id: number, resolutions: string[], selected_option: number): Promise<AxiosResponse> {
    try {
      return await $api.patch(`/connection/${connection_id}`, {
        resolutions,
        selected_option
      });
    }catch (error: any) {
      return error;
    }
  }
}
