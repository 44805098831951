// Http
import $api from '../common/http';

// Core
import { AxiosResponse } from 'axios';
import { EventType } from '../common/enums';

export default class InsightsService {
  static async getInsights(class_id?: number | undefined,): Promise<AxiosResponse> {
    try {
      return await (class_id ? $api.get(`/insights/${class_id}`) : $api.get(`/insights`));
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }
  static async getStudentInsights(student_id: number, type: EventType, days: number, type_id: number | undefined): Promise<AxiosResponse> {
    try {
      const query = type_id ? `&type_id=${type_id}` : '';
      return await $api.get(`/insights/student/${student_id}/${type}?days=${days}${query}`);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async getClassesAndEvents(class_id?: number | undefined, quantity?: number | undefined): Promise<AxiosResponse> {
    try {
      const query = quantity ? `?quantity=${quantity}` : '';
      return await (class_id ? $api.get(`/insights/events/${class_id}${query}`) : $api.get(`/insights/events${query}`));
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async getEventDetails(event_id: number): Promise<AxiosResponse> {
    try {
      return await $api.get(`/insights/event/${event_id}`);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async getConnectionDetails(coonection_id: number): Promise<AxiosResponse> {
    try {
      return await $api.get(`/insights/connection/${coonection_id}`);
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async getDetailedInsights(type: EventType, days: number, class_id: number | undefined): Promise<AxiosResponse> {
    try {
      return await (class_id ? $api.get(`/insights/details/${type}/${class_id}?days=${days}`) : $api.get(`/insights/details/${type}?days=${days}`) );
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }

  static async getInsightGraphDataByType(type: EventType, days: number, class_id: number | undefined, type_id: number | undefined): Promise<AxiosResponse> {
    try {
      const query = type_id ? `&type_id=${type_id}` : '';
      return await (class_id ? $api.get(`/insights/graph/${type}/${class_id}?days=${days}${query}`) : $api.get(`/insights/graph/${type}?days=${days}${query}`) );
    } catch (error: unknown) {
      return error as AxiosResponse;
    }
  }
}
