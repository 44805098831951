export const formatBirthday = (date: Date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1; // Months are zero-indexed
  const day = d.getDate();
  const year = d.getFullYear();

  // Add leading zero to month and day if they are less than 10
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
}
